import React from 'react';

import { SignIn } from './SignIn';

export const Landing = () => {
  return (
    <div style={{ width: '450px' }}>
      <SignIn />
    </div>
  );
};
