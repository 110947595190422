import React, { useState } from 'react';

import { UserInfo, ProductReleases } from '../../../types/ApiResponses';

import { getOauthUrl, getAuthDomain } from '../../../utils/auth';

import { Modal } from '../../shared/modal';

import { BodyText, BodyTextEmphasized, EyebrowText } from '../../shared/text';

import { DefaultButtonWithArrow } from '../../shared/button';

import { Checkbox } from '../../shared/checkbox';

import cookie from 'cookie';

import { Text, Separator, Link } from '@fluentui/react';

const seperatorSmallStyles = {
  root: {
    selectors: {
      '::before': {
        height: '1px',
        background: 'lightgray',
      },
    },
  },
};

interface InstallModalProps {
  availableInstalls: ProductReleases<'available'>;
  userInfo: UserInfo;
  onDismiss: () => any;
}

export const InstallModal = ({
  availableInstalls,
  userInfo,
  onDismiss,
}: InstallModalProps) => {
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [emailConfirmation, setEmailConfirmation] = useState<boolean>(true);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [selectedReleaseId, setSelectedReleaseId] = useState<number>();
  const [currentStep, setCurrentStep] = useState(0);

  const step = steps[currentStep];

  const isFinalStep = currentStep + 1 === steps.length;

  const header = (
    <div>
      <EyebrowText style={{ marginBottom: '0' }}>PRODUCT INSTALL</EyebrowText>
      <BodyText
        variant="small"
        backgroundType="light"
        style={{ marginBottom: '0', lineHeight: 'normal' }}
      >
        (Step {currentStep + 1} of {steps.length})
      </BodyText>
    </div>
  );

  const stepProps = {
    availableInstalls,
    userInfo,
    confirmed,
    setConfirmed,
    emailConfirmation,
    setEmailConfirmation,
    termsAccepted,
    setTermsAccepted,
    isDirty,
    setIsDirty,
    selectedReleaseId,
    setSelectedReleaseId,
  };

  return (
    <Modal
      onDismiss={onDismiss}
      isOpen={true}
      header={header}
      body={step(stepProps)}
      footer={
        <div>
          <DefaultButtonWithArrow
            text={isFinalStep ? 'Proceed' : 'Next'}
            style={{ marginRight: '20px' }}
            backgroundType="light"
            onClick={() => {
              if (step.validate(stepProps) && selectedReleaseId) {
                if (isFinalStep) {
                  const domain = getAuthDomain(userInfo);

                  window.location.assign(
                    getOauthUrl({
                      type: 'install',
                      productReleaseId: selectedReleaseId,
                      csrfToken: cookie.parse(document.cookie)?.['XSRF-TOKEN'],
                      emailNotifications: emailConfirmation,
                      domain,
                    })
                  );
                } else {
                  setIsDirty(false);
                  setCurrentStep(currentStep + 1);
                }
              } else {
                setIsDirty(true);
              }
            }}
          />
          <DefaultButtonWithArrow
            text="Cancel"
            backgroundType="light"
            onClick={onDismiss}
          />
        </div>
      }
    />
  );
};

interface StepProps {
  availableInstalls: ProductReleases<'available'>;
  userInfo: UserInfo;
  confirmed: boolean;
  setConfirmed: (confirmed: boolean) => void;
  emailConfirmation: boolean;
  setEmailConfirmation: (emailConfirmation: boolean) => void;
  termsAccepted: boolean;
  setTermsAccepted: (termsAccepted: boolean) => void;
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
  selectedReleaseId: number | undefined;
  setSelectedReleaseId: (ReleaseId: number | undefined) => void;
}

interface IStep {
  (props: StepProps): React.ReactElement;
  validate: (props: StepProps) => boolean;
}

const ProductSelectionStep: IStep = (props) => {
  const available = props.availableInstalls;
  const padding = '10px';
  return (
    <>
      <BodyText backgroundType="light">
        Select one of the available products to proceed. New products will be
        installed as trial editions, please contact your Mavens representative
        for licensing options.
      </BodyText>

      <table
        style={{
          position: 'relative',
          width: '100%',
          borderCollapse: 'collapse',
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                padding,
                paddingLeft: '0px',
                width: '12%',
                textAlign: 'center',
              }}
            >
              <BodyTextEmphasized style={{ marginBottom: '0px' }}>
                Select
              </BodyTextEmphasized>
            </th>
            <th style={{ padding, width: '40%' }}>
              <BodyTextEmphasized style={{ marginBottom: '0px' }}>
                Product
              </BodyTextEmphasized>
            </th>
            <th style={{ padding }}>
              <BodyTextEmphasized style={{ marginBottom: '0px' }}>
                Version Available
              </BodyTextEmphasized>
            </th>
          </tr>
        </thead>
        <tbody>
          {available.map((pr, i) => (
            <tr
              key={i}
              style={
                i === available.length
                  ? { borderBottom: '1px solid lightgray' }
                  : {}
              }
            >
              <td style={{ padding, paddingLeft: '0px' }}>
                <Checkbox
                  ariaLabel={`select-product-release--${pr.id}`}
                  checked={props.selectedReleaseId === pr.id}
                  onChange={(e, isChecked) =>
                    props.setSelectedReleaseId(isChecked ? pr.id : undefined)
                  }
                  stylesOverrides={{
                    root: { width: 'fit-content', margin: 'auto' },
                  }}
                />
              </td>
              <td style={{ padding }}>
                <BodyText
                  backgroundType="light"
                  style={{ marginBottom: '0px' }}
                >
                  {pr.product.label}
                </BodyText>
              </td>
              <td style={{ padding }}>
                <BodyText
                  backgroundType="light"
                  style={{ marginBottom: '0px' }}
                >
                  {pr.majorVersion}.{pr.minorVersion}.{pr.patchVersion}
                </BodyText>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

ProductSelectionStep.validate = (props) => Boolean(props.selectedReleaseId);

const ConfirmationStep: IStep = (props) => {
  const selectedRelease = props.availableInstalls.find(
    (r) => r.id === props.selectedReleaseId
  );

  const list: Array<{
    label: string;
    value: string | number | boolean | undefined;
  }> = [
    {
      label: 'Organization',
      value: props.userInfo.org.sfOrgId,
    },
    {
      label: 'Product',
      value: selectedRelease?.product.label,
    },
    {
      label: 'Requested version',
      value: selectedRelease?.label,
    },
  ];

  return (
    <>
      {list.map((row) => (
        <div key={row.label}>
          <BodyTextEmphasized
            style={{
              width: '50%',
              display: 'inline-block',
              marginBottom: '0px',
            }}
          >
            {row.label}:
          </BodyTextEmphasized>
          <BodyText
            backgroundType="light"
            style={{
              width: '50%',
              marginBottom: '0px',
              display: 'inline-block',
            }}
          >
            {row.value}
          </BodyText>
          <Separator styles={seperatorSmallStyles} />
        </div>
      ))}
      <BodyText backgroundType="light" warning={true}>
        WARNING: By clicking on "Proceed" below you will initiate an automated
        process that will upgrade your system. It is recommended that Users not
        be active in the system while an upgrade is underway. Please confirm the
        details above before proceeding.
      </BodyText>
      <Checkbox
        stylesOverrides={{ root: { marginBottom: '10px' } }}
        ariaLabel="confirm-installation"
        warning={!props.confirmed && props.isDirty}
        checked={props.confirmed}
        onChange={(e, checked) => props.setConfirmed(Boolean(checked))}
        label="I confirm I want to upgrade my system."
      />
      <Checkbox
        stylesOverrides={{ root: { marginBottom: '10px' } }}
        ariaLabel="accept-terms"
        warning={!props.termsAccepted && props.isDirty}
        checked={props.termsAccepted}
        onChange={(e, checked) => props.setTermsAccepted(Boolean(checked))}
        onRenderLabel={(p) => {
          return (
            <Text
              style={{
                marginLeft: '4px',
              }}
              styles={{
                root: {
                  color:
                    !props.termsAccepted && props.isDirty
                      ? '#EA5976'
                      : '#666666',
                  selectors: {
                    ':hover': {
                      color: p?.theme?.semanticColors.inputTextHovered,
                    },
                  },
                },
              }}
            >
              I consent to the terms and conditions of the{' '}
              <Link
                href={process.env.REACT_APP_TERMS_OF_USE_URL}
                target="_blank"
              >
                Terms of Use
              </Link>
              {' and '}
              <Link
                href={process.env.REACT_APP_PRIVACY_POLICY_URL}
                target="_blank"
              >
                Privacy Policy
              </Link>
              .
            </Text>
          );
        }}
      />
      <Checkbox
        checked={props.emailConfirmation}
        ariaLabel="email-notifications"
        onChange={(e, checked) => props.setEmailConfirmation(Boolean(checked))}
        label="Send me an email confimation once the upgrade has completed."
      />
    </>
  );
};

ConfirmationStep.validate = (props) => props.confirmed && props.termsAccepted;

const steps: Array<IStep> = [ProductSelectionStep, ConfirmationStep];
