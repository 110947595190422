import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';

import {
  Stack,
  getTheme,
  TextField,
  DefaultButton as FluentUiDefaultButton,
  ITextField,
} from '@fluentui/react';

import {
  DefaultButtonWithArrow,
  ActionButton,
  ActionButtonWithArrow,
} from '../../shared/button';
import { HeaderText, BodyText, SubHeaderText } from '../../shared/text';

import { getOauthUrl, setMyDomain, removeMyDomain } from '../../../utils/auth';

/**
 * Display a login button for either the login or test salesforce domains
 *
 * @param domain
 *
 */
const loginButton = (
  domain: 'login.salesforce.com' | 'test.salesforce.com'
) => {
  return (
    <DefaultButtonWithArrow
      text={
        domain === 'login.salesforce.com'
          ? 'Log in to Production'
          : 'Log in to Sandbox'
      }
      onClick={() => {
        removeMyDomain();
        // return true to continue to href
        return true;
      }}
      href={getOauthUrl({ type: 'signin', domain })}
      backgroundType="dark"
    />
  );
};

const isValidUrl = (url: string) => {
  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
};

const CustomDomainSignIn = (props: { onReturn: () => any }) => {
  const theme = getTheme();
  const [domain, setDomain] = useState('');

  const isDomainValid = isValidUrl(`https://${domain}`);

  // focus input on mount
  const inputRef = useRef<ITextField>(null);
  useLayoutEffect(
    function focusInput() {
      inputRef?.current?.focus();
    },
    [inputRef]
  );

  return (
    <div
      style={{
        backgroundColor: 'rgb(255, 255, 255)',
        padding: '2rem',
        width: '100%',
      }}
    >
      <Stack tokens={{ childrenGap: '1rem' }}>
        <SubHeaderText>Use custom domain</SubHeaderText>
        <BodyText backgroundType="light" style={{ marginBottom: '0px' }}>
          To go to your company's login page, enter the organization's domain
          name.
        </BodyText>
        <TextField
          value={domain}
          componentRef={inputRef}
          onChange={(e, value) => setDomain(value ?? '')}
          errorMessage={!isDomainValid && domain ? 'Invalid domain' : ''}
          validateOnFocusOut
          theme={{
            ...theme,
            semanticColors: {
              ...theme.semanticColors,
              inputFocusBorderAlt: 'transparent',
              inputPlaceholderText: 'rgb(205, 205, 205)',
            },
          }}
          placeholder="Organization domain"
          styles={{
            fieldGroup: {
              selectors: {
                ':hover': {
                  borderRight: '2px solid rgb(200, 201, 202)',
                  borderLeft: '2px solid rgb(200, 201, 202)',
                },
              },
              background: 'rgb(244, 245, 246)',
              height: '42px',
              borderTop: '0px',
              borderBottom: '0px',
              borderRight: '2px solid rgb(200, 201, 202)',
              borderLeft: '2px solid rgb(200, 201, 202)',
            },
          }}
        ></TextField>
        <BodyText
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginBottom: '0px',
            width: '100%',
            fontSize: theme.fonts.medium.fontSize,
          }}
          backgroundType="light"
        >
          {`https://${domain ?? ''}`}
        </BodyText>
        <FluentUiDefaultButton
          primary
          text="Continue"
          onClick={() => {
            if (isDomainValid) {
              setMyDomain(domain);
              window.location.assign(getOauthUrl({ type: 'signin', domain }));
            }
          }}
          style={{ width: '100%', height: '42px' }}
        />
        <ActionButtonWithArrow
          text="Back to sign in"
          onClick={() => props.onReturn()}
          iconPosition="left"
          style={{ width: '100%', height: '42px' }}
          stylesOverrides={{
            flexContainer: { justifyContent: 'center' },
            icon: { transform: 'rotate(0deg)' },
          }}
        />
      </Stack>
    </div>
  );
};
/**
 * Display sign in options. Allows users to sign in via default login/test salesforce subdomains and My Domain
 */
export const SignIn = () => {
  const [useCustomDomain, setUseCustomDomain] = useState(false);

  return useCustomDomain ? (
    <CustomDomainSignIn onReturn={() => setUseCustomDomain(false)} />
  ) : (
    <Stack tokens={{ childrenGap: '2rem' }}>
      <div>
        <HeaderText>Installation on demand</HeaderText>
        <BodyText backgroundType="dark">
          Log in to your Salesforce.com instance below to install or upgrade to
          the latest releases of Mavens products.
        </BodyText>
      </div>
      <Stack tokens={{ childrenGap: '3rem' }} horizontal>
        {loginButton('login.salesforce.com')}
        {loginButton('test.salesforce.com')}
      </Stack>
      <Stack tokens={{ childrenGap: '2rem' }} horizontal>
        <ActionButton
          text="Help"
          underlineType="custom"
          href={process.env.REACT_APP_HELP_URL}
          target="_blank"
          iconProps={{ iconName: 'OpenInNewWindow' }}
        ></ActionButton>
        <ActionButton
          text="Use custom domain"
          onClick={() => setUseCustomDomain(true)}
          underlineType="custom"
        ></ActionButton>
      </Stack>
    </Stack>
  );
};
