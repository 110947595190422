// This code is purposefully duplicated from Models.TaskStatus.
// Avoid pulling anything but type information from typeorm entities due to potential bundling/testing issues

import {
  FinalTaskStatus,
  InitialTaskStatus,
} from '@mavens/is-orm/src/models/TaskStatus';

export const isQueuedStatus = (
  status: InitialTaskStatus | string | undefined
): status is InitialTaskStatus => {
  if (status === 'QUEUED') {
    return true;
  } else {
    return false;
  }
};

export const isFinalStatus = (
  status: FinalTaskStatus | string | undefined
): status is FinalTaskStatus => {
  if (status === 'SUCCESS' || status === 'FAIL') {
    return true;
  } else {
    return false;
  }
};
