import React, { useEffect, useState } from 'react';
import { Separator, Stack } from '@fluentui/react';
import {
  Dropdown,
  IDropdownStyles,
  IDropdownProps,
} from 'office-ui-fabric-react';

import { SubHeaderText, BodyText, BodyTextEmphasized } from '../../shared/text';
import { List, ListProps } from '../../shared/list';
import {
  useSelector,
  actionCreators,
  useDispatch,
  RootState,
} from '../../../store';
import { SideNav } from '../../shared/sideNav';
import { isFinalStatus } from '../../../utils/task';

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300, marginBottom: '20px' },
};

const seperatorStyles = {
  root: {
    marginBottom: '20px',
    selectors: {
      '::before': {
        background: 'black',
      },
    },
  },
};

const pollRate = 30000;
const allProductsId = -1;

/**
 * Get label-sorted unique product info from tasks
 *
 * @param tasks
 */
const getSortedUniqueProducts = (tasks: RootState['tasks']) => {
  const productIds = new Map<number, boolean>();
  const products = [];
  for (const task of tasks?.entities ?? []) {
    const product = task.productRelease.product;
    if (!productIds.has(product.id)) {
      productIds.set(product.id, true); // set any value to Map
      products.push(product);
    }
  }

  const sortedProducts = products.sort(
    ({ label: labelA }, { label: labelB }) => {
      return labelA < labelB ? -1 : labelA > labelB ? 1 : 0;
    }
  );

  return sortedProducts;
};

/**
 * capitalize first character in string
 */
const capitalize = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

const getTaskStatusDisplayValue = (text: string) => {
  return text === 'FAIL' ? 'Failed' : capitalize(text);
};

export const Tasks = () => {
  const userInfo = useSelector((state) => state.userInfo);
  const tasks = useSelector((state) => state.tasks);

  const [selectedProductId, setSelectedProductId] = useState(allProductsId);

  const dispatch = useDispatch();

  useEffect(
    function updateSelectedProductFromNewTasks() {
      const taskWithProduct = tasks?.entities?.find(
        (task) => task.productRelease.product.id === selectedProductId
      );

      // if task with selected product doesn't exist, reset to all products filter
      if (selectedProductId !== allProductsId && !taskWithProduct) {
        setSelectedProductId(allProductsId);
      }
    },
    [tasks, selectedProductId]
  );

  useEffect(
    // poll for new tasks
    function poll() {
      const interval = setInterval(() => {
        const orgId = userInfo?.data?.org?.id;
        if (orgId) {
          dispatch(actionCreators.fetchTasks({ orgId }));
        }
      }, pollRate);
      return () => clearInterval(interval);
    },
    [userInfo, dispatch]
  );

  const uniqueProducts = getSortedUniqueProducts(tasks);

  // build dropdown opts from unique product list
  const productFilterDropdownOpts: IDropdownProps['options'] = [
    { key: allProductsId, text: 'All products' },
    ...uniqueProducts.map((product) => ({
      key: product.id,
      text: product.label,
    })),
  ];

  const listColumns: ListProps['columns'] = [
    { key: 'requestedAt', field: 'requestedAt', label: 'REQUESTED AT' },
    { key: 'completedAt', field: 'completedAt', label: 'COMPLETED AT' },
    { key: 'type', field: 'type', label: 'TYPE' },
    { key: 'product', field: 'product', label: 'PRODUCT' },
    { key: 'version', field: 'version', label: 'VERSION' },
    { key: 'performedBy', field: 'performedBy', label: 'PERFORMED BY' },
    { key: 'result', field: 'result', label: 'RESULT' },
  ];

  const ListText = ({ children }: { children: React.ReactNode }) => (
    <BodyText
      style={{ marginBottom: '0px', textOverflow: 'ellipsis', overflow: 'hidden' }}
      variant="medium"
      backgroundType="light"
    >
      {children}
    </BodyText>
  );

  const listItems: ListProps['items'] =
    tasks?.entities
      ?.filter((task) =>
        selectedProductId === allProductsId
          ? true
          : task.productRelease.product.id === selectedProductId
      )
      .map((task) => {
        const status = task.taskStatuses[0];

        return {
          key: task.id,
          requestedAt: (
            <ListText>
              {new Date(task.date).toLocaleString(undefined, {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </ListText>
          ),
          completedAt:
            status && isFinalStatus(status.status) ? (
              <ListText>
                {new Date(status.date).toLocaleString(undefined, {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </ListText>
            ) : (
              ''
            ),
          type: <ListText>{capitalize(task.type)}</ListText>,
          product: <ListText>{task.productRelease.product.label}</ListText>,
          version: <ListText> {task.productRelease.label}</ListText>,
          performedBy: <ListText>{task.user.sfUserId}</ListText>,
          result: task.taskStatuses[0] ? (
            <ListText>{getTaskStatusDisplayValue(status.status)} </ListText>
          ) : null,
        };
      }) ?? [];

  return (
    <Stack
      horizontal
      style={{
        minWidth: '100%',
        minHeight: '400px',
        top: '-65px',
        position: 'relative',
        backgroundImage:
          'linear-gradient(rgb(255, 255, 255), rgb(240, 240, 240))',
      }}
    >
      <Stack.Item grow={0}>
        <div
          style={{
            height: '100%',
            backgroundImage:
              'linear-gradient(rgb(216, 216, 216), rgb(240, 240, 240))',
          }}
        >
          <SideNav />
        </div>
      </Stack.Item>
      <Stack.Item grow={1}>
        <div style={{ margin: '3rem 2.5em' }}>
          <SubHeaderText>Installation History</SubHeaderText>
          <Separator styles={seperatorStyles}></Separator>
          {userInfo?.data?.org?.id && tasks.entities ? (
            <>
              <BodyText style={{ marginBottom: '25px' }} backgroundType="light">
                The table below contains detail on requested installations over
                time into your environment
              </BodyText>
              <BodyTextEmphasized>Product</BodyTextEmphasized>
              <Dropdown
                options={productFilterDropdownOpts}
                selectedKey={selectedProductId}
                onChange={(e, item) => setSelectedProductId(Number(item?.key))}
                styles={dropdownStyles}
              />
              <Separator styles={seperatorStyles}></Separator>
              {tasks.entities.length ? (
                <List columns={listColumns} items={listItems} />
              ) : (
                <BodyText backgroundType="light">
                  No requested installations found
                </BodyText>
              )}
            </>
          ) : (
            <BodyText backgroundType="light">Loading</BodyText>
          )}
        </div>
      </Stack.Item>
    </Stack>
  );
};
