import React from 'react';

import { BodyText } from '../../shared/text';
import { Tasks } from '../../../types/ApiResponses';
import { isFinalStatus, isQueuedStatus } from '../../../utils/task';

/**
 * Display running task information
 *
 * @param {Object} opts - Options
 * @param {string} opts.tasks - Chronological list of tasks (currently only 1 is expected to be running at a time)
 */
export const RunningTasks = ({ tasks }: { tasks: Tasks }) => {
  const runningTask = tasks?.find(
    (task) => !isFinalStatus(task?.taskStatuses?.[0].status)
  );

  const msg = getRunningTaskMessage(runningTask);

  return (
    <BodyText backgroundType="light" warning={msg.warning}>
      {msg.text}
    </BodyText>
  );
};

const getRunningTaskMessage = (runningTask?: Tasks[number]) => {
  const noOpText =
    'No other operations may be performed on this environment until all installations are complete.';

  const runningTaskStatus = runningTask?.taskStatuses?.[0]?.status;

  let text = '';
  if (!runningTask) {
    text = 'No active or pending installations found';
  } else if (isQueuedStatus(runningTaskStatus)) {
    text = `Your org has a pending ${
      runningTask.type === 'INSTALL' ? 'installation' : 'upgrade'
    } of ${runningTask.productRelease.product.label}. ${noOpText}`;
  } else if (!isFinalStatus(runningTaskStatus)) {
    text = `${runningTask.productRelease.product.label} is currently being ${
      runningTask.type === 'INSTALL' ? 'installed' : 'upgraded'
    }. ${noOpText}`;
  }

  return { text, warning: runningTask && !isFinalStatus(runningTaskStatus) };
};
