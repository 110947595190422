import React from 'react';

import { Text, ITextProps } from '@fluentui/react';

export const BodyText = ({
  warning,
  backgroundType,
  style,
  variant,
  children,
  ...rest
}: ITextProps & { warning?: boolean; backgroundType: 'dark' | 'light' }) => {
  const color = warning
    ? '#EA5976'
    : backgroundType === 'dark'
    ? '#CCCCCC'
    : '#666666';
  return (
    <Text
      block
      variant={variant ?? 'mediumPlus'}
      style={{
        lineHeight: '20pt',
        marginBottom: '1rem',
        color,
        ...style,
      }}
      {...rest}
    >
      {children}
    </Text>
  );
};

export const BodyTextEmphasized = ({
  style,
  children,
  ...rest
}: ITextProps) => {
  return (
    <Text
      block
      variant="mediumPlus"
      style={{
        fontWeight: 'bold',
        lineHeight: '20pt',
        color: '#13002B',
        marginBottom: '1rem',
        ...style,
      }}
      {...rest}
    >
      {children}
    </Text>
  );
};
