import { Text } from '@fluentui/react';

import React from 'react';

import { DefaultButtonWithArrow } from '../../../components/shared/button';
import {
  EyebrowText,
  BodyText,
  BodyTextEmphasized,
} from '../../../components/shared/text';
import { Modal } from '../../../components/shared/modal';

export const TaskQueuedModal = ({
  onDismiss,
  type,
}: {
  onDismiss: () => any;
  type: 'UPGRADE' | 'INSTALL';
}) => {
  return (
    <Modal
      onDismiss={onDismiss}
      isOpen={true}
      header={
        <EyebrowText>
          Product {type === 'UPGRADE' ? 'Upgrade' : 'Install'}
        </EyebrowText>
      }
      body={
        <>
          <BodyTextEmphasized>Thank you!</BodyTextEmphasized>
          <BodyText backgroundType="light">
            Your requested {type === 'UPGRADE' ? 'upgrade' : 'install'} will
            commence shortly. Status of your request can be viewed on the home
            page.
          </BodyText>
          <BodyText backgroundType="light">
            If requested, the results of your
            {type === 'UPGRADE' ? ' upgrade' : ' install'} will be delivered to
            you via email once completed.
          </BodyText>
        </>
      }
      footer={
        <DefaultButtonWithArrow
          backgroundType="light"
          text={'Close'}
          onClick={onDismiss}
        />
      }
    />
  );
};
