import React from 'react';

import {
  ActionButton as FluentActionButton,
  IButtonProps,
  IButtonStyles,
} from '@fluentui/react';

type IDefaultButtonProps = Omit<IButtonProps, 'styles'> & {
  stylesOverrides?: IButtonStyles;
  forceActiveStyle?: boolean;
  underlineType?: 'native' | 'custom' | undefined;
  iconPosition?: 'right' | 'left';
};

export const ActionButton = ({
  forceActiveStyle = false,
  stylesOverrides,
  style,
  underlineType,
  iconPosition = 'right',
  ...rest
}: IDefaultButtonProps) => {
  return (
    <FluentActionButton
      style={{ height: '30px', ...style }}
      styles={{
        flexContainer: {
          flexDirection: iconPosition === 'right' ? 'row-reverse' : undefined,
          borderBottom:
            underlineType === 'custom' ? '2px #8D57F1 solid' : undefined,
        },
        textContainer: {
          flexGrow: 0,
        },
        root: {
          fontSize: '16px',
          selectors: {
            ':hover > span.ms-Button-flexContainer': {
              borderBottom:
                underlineType === 'custom' ? '2px #262B66 solid' : undefined,
              marginBottom: underlineType === 'custom' ? '-2px' : undefined,
            },
            'span.ms-Button-flexContainer, i.ms-Button-icon': {
              color: '#8D57F1',
              textDecoration:
                underlineType === 'native' ? 'underline #8D57F1' : undefined,
            },
            ':hover i.ms-Button-icon, :hover span.ms-Button-flexContainer, :active i.ms-Button-icon , :active span.ms-Button-flexContainer': {
              color: '#262B66',
              textDecoration:
                underlineType === 'native' ? 'underline #262B66' : undefined,
            },
          },
        },
        ...stylesOverrides,
      }}
      {...rest}
    />
  );
};

export const ActionButtonWithArrow = (props: IDefaultButtonProps) => (
  <ActionButton
    iconProps={{ iconName: 'SkypeArrow' }}
    stylesOverrides={{ icon: { transform: 'rotate(180deg)' } }}
    {...props}
  ></ActionButton>
);
