import React from 'react';

import { Text, ITextProps } from '@fluentui/react';

export const HeaderText = ({ children, style, ...rest }: ITextProps) => {
  return (
    <Text
      block
      variant="xxLargePlus"
      style={{
        lineHeight: '52pt',
        color: '#FFFFFF',
        ...style,
      }}
      {...rest}
    >
      {children}
    </Text>
  );
};
