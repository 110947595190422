import React from 'react';

import { Text, ITextProps } from '@fluentui/react';

export const EyebrowText = ({ style, children, ...rest }: ITextProps) => {
  return (
    <Text
      block
      variant="small"
      style={{
        lineHeight: '13pt',
        fontWeight: 'bold',
        letterSpacing: '1px',
        color: '#13002B',
        marginBottom: '1rem',
        textTransform: 'uppercase',
        ...style,
      }}
      {...rest}
    >
      {children}
    </Text>
  );
};
