import React from 'react';

import { Stack } from '@fluentui/react';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { DefaultButtonWithArrow, ActionButton } from '../button';

export const SideNav = () => {
  const history = useHistory();

  return (
    <>
      <Stack
        tokens={{ childrenGap: '20px' }}
        className="SideNav"
        style={{
          padding: '3rem 2rem 0',
        }}
      >
        <Stack.Item>
          <DefaultButtonWithArrow
            text="Home"
            forceActiveStyle={Boolean(useRouteMatch('/home'))}
            onClick={() => history.push('/home')}
            backgroundType="light"
            style={{ width: '100%', minWidth: '180px' }}
          />
        </Stack.Item>
        <Stack.Item>
          <DefaultButtonWithArrow
            text="History"
            forceActiveStyle={Boolean(useRouteMatch('/history'))}
            onClick={() => history.push('/history')}
            backgroundType="light"
            style={{ width: '100%', minWidth: '180px' }}
          />
        </Stack.Item>
        <Stack.Item>
          <ActionButton
            underlineType="custom"
            href={process.env.REACT_APP_HELP_URL}
            target="_blank"
            text="Help"
            iconProps={{ iconName: 'OpenInNewWindow' }}
          ></ActionButton>
        </Stack.Item>
      </Stack>
    </>
  );
};
