import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getProductReleases } from '../utils/api';
import { ProductReleases } from '../types/ApiResponses';

export const fetchAvailableReleases = createAsyncThunk(
  'availableReleases/fetch',
  async (orgId: number) => {
    const userInfoRes = await getProductReleases({
      orgId,
      type: 'available',
    });

    return userInfoRes.data;
  }
);

interface AvailableReleasesState {
  entities?: ProductReleases<'available'>;
  loading: boolean;
  error?: string;
}
const availableReleasesSlice = createSlice({
  name: 'installedReleases',
  initialState: { loading: false } as AvailableReleasesState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAvailableReleases.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAvailableReleases.fulfilled, (state, action) => {
      state.loading = false;
      state.entities = action.payload;
    });
    builder.addCase(fetchAvailableReleases.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.error?.message ?? 'An error occurred';
    });
  },
});

export default availableReleasesSlice;
