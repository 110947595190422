import { Image, Stack, ActionButton } from '@fluentui/react';

import { HeaderText, BodyText } from '../shared/text';

import { logout } from '../../utils/api';

import { UserInfo } from '../../types/ApiResponses';

import React from 'react';

import Logo from '../../assets/images/mavens-logo-light.png';

import { useHistory } from 'react-router-dom';

const LogoutButton = () => {
  return (
    <ActionButton
      iconProps={{ iconName: 'SkypeArrow' }}
      styles={{
        icon: {
          transform: 'rotate(180deg)',
          fontSize: '11px',
          marginLeft: '0px',
        },
        flexContainer: {
          flexDirection: 'row-reverse',
          borderBottom: undefined,
        },
        textContainer: {
          flexGrow: 0,
        },
        root: {
          fontSize: '11px',
          lineHeight: '13pt',
          // fontWeight: 'bold',
          letterSpacing: '1.5px',
          color: '#13002B',
          marginBottom: '1rem',
          textTransform: 'uppercase',
          selectors: {
            ':hover > span.ms-Button-flexContainer': {
              borderBottom: undefined,
              marginBottom: undefined,
            },
            'span.ms-Button-flexContainer, i.ms-Button-icon': {
              color: '#FFFFFF',
              textDecoration: undefined,
            },
            ':hover i.ms-Button-icon, :hover span.ms-Button-flexContainer, :active i.ms-Button-icon , :active span.ms-Button-flexContainer': {
              color: '#FFFFFF',
              textDecoration: undefined,
            },
          },
        },
      }}
      onClick={() => logout()}
      text="Logout"
    ></ActionButton>
  );
};

export const AppHeader = (props: {
  showWelcome: boolean;
  showLogout: boolean;
  userInfo?: UserInfo;
}) => {
  const history = useHistory();
  const { userInfo } = props;

  return (
    <div style={{ minHeight: '200px' }}>
      <Stack
        style={{ marginBottom: '50px', marginTop: '10px' }}
        horizontal
        horizontalAlign="space-between"
      >
        <Image
          src={Logo}
          alt="Mavens logo"
          shouldFadeIn={false}
          style={{ cursor: 'pointer' }}
          onClick={() => history.push('/')}
        />
        <div style={{ visibility: props.showLogout ? undefined : 'hidden' }}>
          <LogoutButton />
        </div>
      </Stack>
      {props.showWelcome ? (
        <div style={{ marginBottom: '100px' }}>
          <HeaderText style={{ color: '#FFFFFF' }}>Welcome</HeaderText>
          <BodyText
            backgroundType="dark"
            style={{
              visibility: userInfo ? undefined : 'hidden',
              color: '#CCCCCC',
            }}
          >
            Organization: {userInfo?.org?.sfOrgId}
          </BodyText>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
