import React from 'react';

import {
  Checkbox as FluentCheckbox,
  ICheckboxProps,
  ICheckboxStyles,
} from '@fluentui/react';

export const Checkbox = ({
  warning,
  stylesOverrides,
  ...rest
}: Omit<ICheckboxProps, 'styles'> & {
  stylesOverrides?: ICheckboxStyles;
  warning?: boolean;
}) => {
  return (
    <FluentCheckbox
      styles={{
        text: {
          color: warning ? '#EA5976' : '#666666',
        },
        ...stylesOverrides,
      }}
      {...rest}
    ></FluentCheckbox>
  );
};
