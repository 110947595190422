import React, { useState } from 'react';

import { UserInfo, ProductReleases } from '../../../types/ApiResponses';

import { getOauthUrl, getAuthDomain } from '../../../utils/auth';

import { Modal } from '../../shared/modal';
import { BodyText, BodyTextEmphasized, EyebrowText } from '../../shared/text';

import { DefaultButtonWithArrow, ActionButton } from '../../shared/button';

import { List } from '../../shared/list';

import { Checkbox } from '../../shared/checkbox';

import cookie from 'cookie';

import { Text, Separator, Icon, TooltipHost, Link } from '@fluentui/react';

const seperatorSmallStyles = {
  root: {
    selectors: {
      '::before': {
        height: '1px',
        background: 'lightgray',
      },
    },
  },
};

// requested installation is a retry if already installed
const getIsRetry = (
  installed?: ProductReleases<'installed'>[number],
  requested?: ProductReleases<'available'>[number]
) => installed && installed?.id === requested?.id;

export const InstalledReleasesList = ({
  availableReleases,
  installedReleases,
  userInfo,
  newTasksEnabled,
}: {
  availableReleases: ProductReleases<'available'>;
  installedReleases: ProductReleases<'installed'>;
  userInfo: UserInfo;
  newTasksEnabled: boolean;
}) => {
  const getAvailableInstallsForRelease = (
    release: ProductReleases<'installed'>[number]
  ) => {
    return availableReleases
      ?.filter((available) => available.product.id === release.product.id)
      ?.sort((a, b) => b.majorVersion - a.majorVersion);
  };

  const [
    installModalProps,
    setInstallModalProps,
  ] = useState<InstallModalProps | null>(null);

  const list = (
    <List
      columns={[
        { field: 'product', label: 'Products', key: 'product' },
        { field: 'version', label: 'Installed Version', key: 'version' },
        { field: 'action', label: 'Actions', key: 'action' },
      ]}
      items={
        installedReleases?.map<{
          product: any;
          version: any;
          action: any;
          key: string;
        }>((installed) => {
          const available = getAvailableInstallsForRelease(installed);
          const isPostInstallFailure =
            installed.tasks[0]?.taskStatuses?.[0].status === 'FAIL';

          return {
            key: String(installed.id),
            product: (
              <BodyText style={{ marginBottom: '0px' }} backgroundType="light">
                {installed.product.label}
                {isPostInstallFailure ? (
                  <TooltipHost
                    content={
                      <BodyText
                        style={{ marginBottom: '0px' }}
                        backgroundType="light"
                      >
                        One or more components failed to install successfully.
                        Please reattempt the operation to resolve the issue. If
                        this issue persists please contact Mavens Customer
                        Support.
                      </BodyText>
                    }
                  >
                    <Icon
                      iconName="info"
                      style={{
                        color: 'red',
                        paddingLeft: '.3rem',
                        verticalAlign: 'middle',
                      }}
                    />
                  </TooltipHost>
                ) : (
                  ''
                )}
              </BodyText>
            ),
            version: (
              <BodyText style={{ marginBottom: '0px' }} backgroundType="light">
                {installed.label +
                  (installed?.tasks?.[0]
                    ? `, installed on ${new Date(
                        installed.tasks[0].date
                      ).toLocaleDateString()} by User ${
                        installed.tasks[0].user.sfUserId
                      }`
                    : '')}
              </BodyText>
            ),
            action:
              available?.length && newTasksEnabled ? (
                <ActionButton
                  text={isPostInstallFailure ? 'Retry' : 'Upgrade'}
                  stylesOverrides={{ label: { lineHeight: '17pt' } }}
                  style={{
                    fontSize: '17px',
                    height: 'auto',
                    marginLeft: '-9px',
                  }}
                  underlineType="native"
                  disabled={!newTasksEnabled}
                  onClick={() =>
                    setInstallModalProps({
                      installed: installed,
                      requested: available[0],
                      userInfo: userInfo,
                      isOpen: true,
                      onDismiss: () => setInstallModalProps(null),
                    })
                  }
                />
              ) : (
                <BodyText backgroundType="light">None Available</BodyText>
              ),
          };
        }) ?? []
      }
    />
  );

  return (
    <>
      {installedReleases.length ? (
        list
      ) : (
        <BodyText backgroundType="light">
          No supported products are currently installed
        </BodyText>
      )}
      {installModalProps ? (
        <InstallModal
          {...installModalProps}
          onDismiss={() => setInstallModalProps(null)}
        />
      ) : (
        ''
      )}
    </>
  );
};

interface InstallModalProps {
  installed: ProductReleases<'installed'>[number];
  requested: ProductReleases<'available'>[number];
  userInfo: UserInfo;
  isOpen: boolean;
  onDismiss: () => any;
}

const InstallModal = ({
  installed,
  requested,
  onDismiss,
  userInfo,
}: InstallModalProps) => {
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [emailConfirmation, setEmailConfirmation] = useState<boolean>(true);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  const [isSubmitDirty, setIsSubmitDirty] = useState<boolean>(false);

  const isRetry = getIsRetry(installed, requested);

  const rows: Array<{
    label: string;
    value: string | number | boolean | undefined;
  }> = [
    {
      label: 'Organization',
      value: userInfo.org.sfOrgId,
    },
    {
      label: 'Product',
      value: installed?.product.label,
    },
    {
      label: 'Installed version',
      value: installed.label,
    },
    {
      label: 'Available upgraded version',
      value: requested.label,
    },
  ];

  const header = (
    <EyebrowText>
      {isRetry ? 'Retry' : ''} Product
      {isRetry
        ? ' ' +
          installed.tasks[0].type.charAt(0) +
          installed.tasks[0].type.substring(1).toLowerCase()
        : ' Upgrade'}
    </EyebrowText>
  );

  return (
    <Modal
      onDismiss={onDismiss}
      isOpen={true}
      header={header}
      body={
        <div>
          {rows.map((row) => (
            <div key={row.label}>
              <BodyTextEmphasized
                style={{
                  width: '50%',
                  display: 'inline-block',
                  marginBottom: '0px',
                }}
              >
                {row.label}:
              </BodyTextEmphasized>
              <BodyText
                backgroundType="light"
                style={{
                  width: '50%',
                  marginBottom: '0px',
                  display: 'inline-block',
                }}
              >
                {row.value}
              </BodyText>
              <Separator styles={seperatorSmallStyles} />
            </div>
          ))}
          <BodyText backgroundType="light" warning={true}>
            WARNING: By clicking on "Proceed" below you will initiate an
            automated process that will upgrade your system. It is recommended
            that Users not be active in the system while an upgrade is underway.
            Please confirm the details above before proceeding.
          </BodyText>
          <Checkbox
            stylesOverrides={{ root: { marginBottom: '10px' } }}
            warning={!confirmed && isSubmitDirty}
            checked={confirmed}
            onChange={() => setConfirmed(!confirmed)}
            label="I confirm I want to upgrade my system."
          />
          <Checkbox
            stylesOverrides={{ root: { marginBottom: '10px' } }}
            warning={!termsAccepted && isSubmitDirty}
            checked={termsAccepted}
            onChange={() => setTermsAccepted(!termsAccepted)}
            onRenderLabel={(p) => {
              return (
                <Text
                  style={{
                    marginLeft: '4px',
                  }}
                  styles={{
                    root: {
                      color:
                        !termsAccepted && isSubmitDirty ? '#EA5976' : '#666666',
                      selectors: {
                        ':hover': {
                          color: p?.theme?.semanticColors.inputTextHovered,
                        },
                      },
                    },
                  }}
                >
                  I consent to the terms and conditions of the{' '}
                  <Link
                    href={process.env.REACT_APP_TERMS_OF_USE_URL}
                    target="_blank"
                  >
                    Terms of Use
                  </Link>
                  {' and '}
                  <Link
                    href={process.env.REACT_APP_PRIVACY_POLICY_URL}
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                  .
                </Text>
              );
            }}
          />
          <Checkbox
            checked={emailConfirmation}
            onChange={() => setEmailConfirmation(!emailConfirmation)}
            label="Send me an email confimation once the upgrade has completed."
          />
        </div>
      }
      footer={
        <div>
          <DefaultButtonWithArrow
            text="Proceed"
            style={{ marginRight: '20px' }}
            backgroundType="light"
            onClick={() => {
              setIsSubmitDirty(true);
              const isValid = confirmed && termsAccepted;
              const domain = getAuthDomain(userInfo);

              if (isValid) {
                window.location.href = getOauthUrl({
                  type: 'install',
                  productReleaseId: requested.id,
                  csrfToken: cookie.parse(document.cookie)?.['XSRF-TOKEN'],
                  emailNotifications: emailConfirmation,
                  domain,
                });
              }
            }}
          />
          <DefaultButtonWithArrow
            text="Cancel"
            backgroundType="light"
            onClick={onDismiss}
          />
        </div>
      }
    />
  );
};
