import { Image, Stack, Text, Link } from '@fluentui/react';

import React from 'react';

import { BodyText } from '../shared/text';

import Logo from '../../assets/images/mavens-logo-light.png';

export const AppFooter = () => {
  return (
    <Stack
      tokens={{ childrenGap: '4rem' }}
      horizontal
      style={{ width: '100%', backgroundColor: 'black', color: 'white' }}
    >
      <Stack tokens={{ childrenGap: '50px' }} style={{ width: '300px' }}>
        <Stack.Item align="baseline">
          <Image
            src={Logo}
            shouldFadeIn={false}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              window.location.href = 'https://www.mavens.com';
            }}
            alt="Mavens logo"
          />
        </Stack.Item>
        <BodyText
          style={{ color: '#FFFFFF', lineHeight: '16pt' }}
          backgroundType="dark"
          variant="smallPlus"
        >
          © 2007-{new Date().getFullYear()} Mavens Consulting Services, Inc. All
          rights reserved. Various trademarks held by their respective owners.
          <br />
          <Link href="mailto: info@mavens.com">info@mavens.com</Link>
          <br />
          +1 312.725.8528
        </BodyText>
      </Stack>

      <Stack
        style={{ width: 'auto' }}
        tokens={{ childrenGap: '4rem' }}
        horizontal
      >
        <div>
          <Link
            style={{ color: '#FFFFFF' }}
            href={process.env.REACT_APP_PRIVACY_POLICY_URL}
            target="_blank"
          >
            Privacy Policy
          </Link>
        </div>
        <div>
          <Link
            style={{ color: '#FFFFFF' }}
            href={process.env.REACT_APP_TERMS_OF_USE_URL}
            target="_blank"
          >
            Terms of Use
          </Link>
        </div>
      </Stack>
    </Stack>
  );
};
