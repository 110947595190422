import React from 'react';

import { Stack } from '@fluentui/react';
import { useHistory, useParams } from 'react-router-dom';
import { DefaultButtonWithArrow, ActionButton } from '../../shared/button';
import { HeaderText, BodyText } from '../../shared/text';

const getErrorTextByType = (errorType: 'login' | 'authorization') =>
  errorType === 'login'
    ? 'An error occurred while logging in to Salesforce.com. Please verify your credentials and try again.'
    : errorType === 'authorization'
    ? 'You are not currently authorized to perform installations and upgrades on the selected Salesforce.com instance.  Please contact your Salesforce.com System Administrator for additional assistance.'
    : errorType === '404'
    ? 'We couldn’t find that page'
    : 'An error has occured';

export const Error = () => {
  const history = useHistory();
  const { errorType } = useParams();

  return (
    <Stack
      style={{ width: '50%', minWidth: '500px' }}
      tokens={{ childrenGap: '2rem' }}
      horizontalAlign="start"
    >
      <Stack>
        <HeaderText block>Oops...</HeaderText>
        <BodyText backgroundType="dark">
          {getErrorTextByType(errorType)}
        </BodyText>
      </Stack>
      <Stack
        tokens={{ childrenGap: '4rem' }}
        horizontal
        horizontalAlign="center"
      >
        <DefaultButtonWithArrow
          backgroundType="dark"
          text="Return"
          onClick={() => history.push('/')}
          primary
        />
      </Stack>
      <ActionButton
        underlineType="custom"
        href="https://www.google.com"
        target="_blank"
        text="Help"
        iconProps={{ iconName: 'OpenInNewWindow' }}
      ></ActionButton>
    </Stack>
  );
};
