import React, { useEffect } from 'react';
import { Landing, Error, Home, Tasks } from '../pages';
import './App.scss';
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { AppHeader } from './AppHeader';
import { AppFooter } from './AppFooter';

import { Stack } from '@fluentui/react';

import { initializeIcons } from '@uifabric/icons';

import { isAuthenticated } from '../../utils/auth';
import { loadTheme } from '../../utils/styles';

import { useSelector, useDispatch, actionCreators } from '../../store';

initializeIcons();
loadTheme();

const createTitle = (page?: string) => (
  <Helmet>
    <title>{`Mavens Install Service${page ? ` - ${page}` : ''}`}</title>
  </Helmet>
);

const Background = (props: { showClouds: boolean }) => {
  return (
    <div className="bg">
      {props.showClouds ? <div className="bg__clouds"></div> : ''}
      <div className="bg__grid"></div>
    </div>
  );
};

export const App = () => {
  const isErrorScreen = Boolean(useRouteMatch('/error'));
  const isLandingScreen = Boolean(useRouteMatch({ path: '/', exact: true }));

  const isPublicRoute = Boolean(isErrorScreen || isLandingScreen);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isPublicRoute) {
      dispatch(actionCreators.fetchUserInfo());
    }
  }, [isPublicRoute, dispatch]);

  const userInfo = useSelector((state) => state.userInfo);

  useEffect(() => {
    const orgId = userInfo.data?.org.id;
    if (orgId) {
      dispatch(actionCreators.fetchTasks({ orgId }));
      dispatch(actionCreators.fetchInstalledReleases(orgId));
      dispatch(actionCreators.fetchAvailableReleases(orgId));
    }
  }, [userInfo, dispatch]);

  console.log('TEST', userInfo);

  const page = (
    <div style={{ height: '100%' }}>
      <Switch>
        <Route exact path="/">
          {createTitle()}
          <Landing />
        </Route>
        <Route path="/home">
          {createTitle('Home')}
          <Home />
        </Route>
        <Route path="/history">
          {createTitle('Tasks')}
          <Tasks />
        </Route>
        <Route path="/error/:errorType?">
          {createTitle('Error')}
          <Error />
        </Route>
        <Route path="*">
          <Redirect to={{ pathname: '/error/404' }} />
        </Route>
      </Switch>
    </div>
  );

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Background showClouds={isPublicRoute} />
      <Stack className="App" style={{ minHeight: '100vh' }}>
        <Stack.Item grow={0}>
          <AppHeader
            userInfo={userInfo.data}
            showWelcome={!isPublicRoute}
            showLogout={!isPublicRoute}
          />
        </Stack.Item>
        <Stack.Item
          styles={{
            root: {
              width: isPublicRoute ? '' : 'calc(100% + 2rem)',
              backgroundColor: isPublicRoute ? '' : 'rgb(240, 240, 240)',
              marginLeft: isPublicRoute ? '' : '-2rem', // cancel out the sidenav margin
              marginRight: isPublicRoute ? '' : '-2rem',
            },
          }}
          grow={1}
        >
          {page}
        </Stack.Item>
        <Stack.Item grow={0}>
          <AppFooter />
        </Stack.Item>
      </Stack>
    </div>
  );
};
