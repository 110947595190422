import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getUserInfo } from '../utils/api';
import { UserInfo } from '../types/ApiResponses';

export const fetchUserInfo = createAsyncThunk('userInfo/fetch', async () => {
  const userInfoRes = await getUserInfo();

  return userInfoRes.data;
});

interface UserInfoState {
  data?: UserInfo;
  loading: boolean;
  error?: string;
}
const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: { loading: false } as UserInfoState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchUserInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.error?.message ?? 'An error occurred';
    });
  },
});

export default userInfoSlice;
