import React, { useEffect, useState } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';

import { InstalledReleasesList } from './InstalledReleasesList';
import { RunningTasks } from './RunningTasks';
import { TaskQueuedModal } from './TaskQueuedModal';
import { ActionButtonWithArrow } from '../../shared/button';
import { Separator, Stack } from '@fluentui/react';

import { SubHeaderText, BodyText } from '../../shared/text';

import { InstallModal } from './InstallModal';

import { useSelector, actionCreators, useDispatch } from '../../../store';

import { getAvailableInstalls } from './util';

import { SideNav } from '../../shared/sideNav';

const seperatorStyles = {
  root: {
    marginBottom: '20px',
    selectors: {
      '::before': {
        background: 'black',
      },
    },
  },
};

const pollRate = 30000;

export const Home = () => {
  const userInfo = useSelector((state) => state.userInfo);
  const tasks = useSelector((state) => state.tasks);
  const installedReleases = useSelector((state) => state.installedReleases);
  const availableReleases = useSelector((state) => state.availableReleases);

  const [showInstallModal, setShowInstallModal] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(
    // poll to see if tasks completed or installed/available products changed
    function poll() {
      const interval = setInterval(() => {
        const orgId = userInfo?.data?.org?.id;
        if (orgId) {
          dispatch(actionCreators.fetchTasks({ orgId }));
          dispatch(actionCreators.fetchInstalledReleases(orgId));
          dispatch(actionCreators.fetchAvailableReleases(orgId));
        }
      }, pollRate);
      return () => clearInterval(interval);
    },
    [userInfo, dispatch]
  );

  return (
    <Stack
      horizontal
      style={{
        minWidth: '100%',
        minHeight: '400px',
        top: '-65px',
        position: 'relative',
        backgroundImage:
          'linear-gradient(rgb(255, 255, 255), rgb(240, 240, 240))',
      }}
    >
      <Stack.Item grow={0}>
        <div
          style={{
            height: '100%',
            backgroundImage:
              'linear-gradient(rgb(216, 216, 216), rgb(240, 240, 240))',
          }}
        >
          <SideNav />
        </div>
      </Stack.Item>
      <Stack.Item grow={1}>
        <div style={{ margin: '3rem 2.5em' }}>
          <SubHeaderText>Active and Pending Installations</SubHeaderText>
          <Separator styles={seperatorStyles}></Separator>
          {userInfo?.data?.org?.id && tasks.entities ? (
            <RunningTasks tasks={tasks.entities} />
          ) : (
            <BodyText backgroundType="light">Loading</BodyText>
          )}
          <Stack
            verticalAlign="center"
            horizontalAlign="space-between"
            horizontal
            style={{ marginTop: '40px' }}
          >
            <Stack.Item grow={1}>
              <SubHeaderText>Installed Products</SubHeaderText>
            </Stack.Item>
            {tasks.allowNewTasks ? (
              <ActionButtonWithArrow
                text="Install Product"
                underlineType="custom"
                onClick={() => setShowInstallModal(true)}
              ></ActionButtonWithArrow>
            ) : (
              ''
            )}
          </Stack>
          <Separator styles={seperatorStyles}></Separator>
          {!userInfo.loading &&
          userInfo?.data?.org?.id &&
          installedReleases.entities &&
          availableReleases.entities ? (
            <InstalledReleasesList
              installedReleases={installedReleases.entities}
              availableReleases={availableReleases.entities}
              userInfo={userInfo.data}
              newTasksEnabled={tasks.allowNewTasks}
            />
          ) : (
            <BodyText backgroundType="light">
              Loading installed products
            </BodyText>
          )}

          {showInstallModal &&
          userInfo.data &&
          availableReleases.entities &&
          installedReleases.entities ? (
            <InstallModal
              onDismiss={() => setShowInstallModal(false)}
              userInfo={userInfo.data}
              availableInstalls={getAvailableInstalls(
                availableReleases.entities
              )}
            />
          ) : (
            ''
          )}
        </div>
      </Stack.Item>
      <Route path="/home/upgradeQueued">
        <TaskQueuedModal
          type="UPGRADE"
          onDismiss={() => history.push('/home')}
        />
      </Route>
      <Route path="/home/installQueued">
        <TaskQueuedModal
          type="INSTALL"
          onDismiss={() => history.push('/home')}
        />
      </Route>
    </Stack>
  );
};
