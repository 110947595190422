import React, { ReactElement } from 'react';

import {
  IconButton,
  getTheme,
  mergeStyleSets,
  Modal as FluentModal,
  IModalProps,
} from '@fluentui/react';

interface ModalProps extends IModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  header?: ReactElement;
  body?: ReactElement;
  footer?: ReactElement;
}

/**
 * @description Modal extends fluent uis modal with header, body, and footer.
 */
export const Modal = ({
  header,
  body,
  footer,
  onDismiss,
  ...fluentModalProps
}: ModalProps) => {
  const theme = getTheme();
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      width: '40vw',
      minWidth: '600px',
      alignItems: 'stretch',
    },
    header: [
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        color: theme.palette.neutralPrimary,
        display: 'flex',
        padding: '24px 12px 14px 24px',
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: '100px' },
      },
    },
    footer: {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      padding: '12px 12px 24px 24px',
    },
  });

  const iconButtonStyles = {
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: 'auto',
      marginTop: '-4px',
      marginRight: '2px',
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };

  return (
    <FluentModal onDismiss={onDismiss} {...fluentModalProps}>
      <div className={contentStyles.container}>
        <div className={contentStyles.header}>
          {header}
          {onDismiss ? (
            <IconButton
              styles={iconButtonStyles}
              iconProps={{ iconName: 'cancel' }}
              ariaLabel="Close popup modal"
              onClick={onDismiss}
            />
          ) : (
            <></>
          )}
        </div>
        <div className={contentStyles.body}>{body}</div>
        <div className={contentStyles.footer}>{footer}</div>
      </div>
    </FluentModal>
  );
};
