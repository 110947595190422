import React from 'react';

import { Separator, ISeparatorProps } from '@fluentui/react';
import { EyebrowText } from '../text';

const separatorBigStyles: ISeparatorProps['styles'] = {
  root: {
    fontSize: '40px',
    selectors: {
      '::before': {
        height: '1px',
        background: 'lightgray',
      },
    },
  },
};

export interface ListProps {
  columns: Array<{
    key: string | number;
    label: string;
    field: string;
  }>;
  items: Array<{ [field: string]: any; key: string | number }>;
}

/**
 *
 * Display a list
 *
 * @param {Object} props - list props
 * @param {Array} columns - list columns
 * @param {Array} items - list items
 *
 */
export const List = ({ columns, items }: ListProps) => {
  const columnWidth = `${100 / columns.length}%`;

  return items.length && columns.length ? (
    <div style={{ width: '100%' }}>
      <div>
        {columns.map(({ key, label }) => (
          <div
            key={key}
            style={{
              display: 'inline-block',
              width: columnWidth,
            }}
          >
            <EyebrowText>{label}</EyebrowText>
          </div>
        ))}
        {items.map((item, itemIdx) => (
          <div key={item.key}>
            <div key={item.key} style={{ display: 'flex' }}>
              {columns.map(({ field, key: columnKey }) => (
                <div
                  key={`${columnKey}-${item.key}`}
                  style={{
                    display: 'inline-block',
                    width: columnWidth,
                    paddingRight: '14px',
                    boxSizing: 'border-box',
                  }}
                >
                  {item[field]}
                </div>
              ))}
            </div>
            {itemIdx < items.length - 1 ? (
              <Separator styles={separatorBigStyles} />
            ) : null}
          </div>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
};
