import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getProductReleases } from '../utils/api';
import { ProductReleases } from '../types/ApiResponses';

export const fetchInstalledReleases = createAsyncThunk(
  'installedReleases/fetch',
  async (orgId: number) => {
    const userInfoRes = await getProductReleases({
      orgId,
      type: 'installed',
    });

    return userInfoRes.data;
  }
);

interface InstalledReleasesState {
  entities?: ProductReleases<'installed'>;
  loading: boolean;
  error?: string;
}
const installedReleasesSlice = createSlice({
  name: 'installedReleases',
  initialState: { loading: false } as InstalledReleasesState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInstalledReleases.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchInstalledReleases.fulfilled, (state, action) => {
      state.loading = false;
      state.entities = action.payload;
    });
    builder.addCase(fetchInstalledReleases.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.error?.message ?? 'An error occurred';
    });
  },
});

export default installedReleasesSlice;
