import axios from 'axios';
import { UserInfo, ProductReleases, Tasks } from '../types/ApiResponses';

import { ApiQueryParams } from '@mavens/is-types';

axios.defaults.baseURL =
  process.env.REACT_APP_DEV_API_DOMAIN_OVERRIDE ?? axios.defaults.baseURL;

axios.interceptors.response.use(
  (res) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return res;
  },
  (err) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (err?.response?.status === 401 || err?.response?.status === 403) {
      window.location.href = '/';
    }
    return Promise.reject(err);
  }
);

export const getUserInfo = () => {
  return axios.get<UserInfo>('/api/users/me');
};

export const getProductReleases = <
  T extends ApiQueryParams.ProductReleaseType.Union
>({
  orgId,
  type,
}: {
  orgId: number;
  type: T;
}) => {
  return axios.get<ProductReleases<T>>(`/api/orgs/${orgId}/productReleases`, {
    params: { type },
  });
};

export const getTasks = ({ orgId, take }: { orgId: number; take: number }) => {
  return axios.get<Tasks>(`/api/orgs/${orgId}/tasks`, { params: { take } });
};

export const logout = async () => {
  await axios.post('/api/logout');
  window.location.href = '/';
};
