import { loadTheme as fluentLoadTheme } from '@fluentui/react';

export const loadTheme = () => {
  fluentLoadTheme({
    palette: {
      themePrimary: '#8D57F1',
      themeDarkAlt: '#453a6e',
      themeLighter: '#e6e5ee', // focus
      themeDark: '#453a6e',
      themeLighterAlt: '#eff6fc',
      themeLight: '#c7e0f4',
      themeTertiary: '#71afe5',
      themeDarker: '#004578',
      neutralLighterAlt: '#f8f8f8',
      neutralLighter: '#f4f4f4',
      neutralLight: '#eaeaea',
      neutralQuaternaryAlt: '#dadada',
      neutralQuaternary: '#d0d0d0',
      neutralTertiaryAlt: '#c8c8c8',
      neutralTertiary: '#c2c2c2',
      neutralSecondary: '#555555',
      neutralPrimaryAlt: '#4b4b4b',
      neutralPrimary: '#333333',
      neutralDark: '#272727',
      black: '#1d1d1d',
      white: '#ffffff',
    },

    defaultFontStyle: {
      fontFamily: 'Ariel, Lato, sans-serif',
      fontWeight: 'normal',
    },
    fonts: {
      small: {
        fontSize: '11px',
      },
      medium: {
        fontSize: '15px',
      },
      mediumPlus: {
        fontSize: '17px',
      },
      large: {
        fontSize: '20px',
        // @ts-ignore
        fontWeight: 'semibold',
      },
      xLarge: {
        fontSize: '22px',
        // @ts-ignore
        fontWeight: 'semibold',
      },
      xLargePlus: {
        fontWeight: '500',
      },
      xxLargePlus: {
        fontSize: '40px',
        fontWeight: 'bold',
      },
    },
  });
};
