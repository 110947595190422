import React from 'react';

import {
  DefaultButton as FluentDefaultButton,
  IButtonProps,
  IButtonStyles,
} from '@fluentui/react';

type IDefaultButtonProps = Omit<IButtonProps, 'styles'> & {
  backgroundType: 'light' | 'dark';
  borderRadiusType?: 'round' | 'flat';
  stylesOverrides?: IButtonStyles;
  forceActiveStyle?: boolean;
};

export const DefaultButton = ({
  backgroundType,
  borderRadiusType = 'round',
  forceActiveStyle = false,
  stylesOverrides,
  disabled,
  style,
  ...rest
}: IDefaultButtonProps) => {
  return backgroundType === 'dark' ? (
    <FluentDefaultButton
      style={{ minWidth: '180px', ...style }}
      styles={{
        flexContainer: {
          flexDirection: 'row-reverse',
        },
        textContainer: {
          flexGrow: 0,
        },
        root: {
          padding: '20px 10px',
          borderColor: '#8D57F1',
          background: `rgba(141, 87, 241, ${forceActiveStyle ? '0.6' : '0'})`,
          borderRadius: borderRadiusType === 'round' ? '20px' : '',
          selectors: {
            'i, span': {
              color: '#FFFFFF',
            },
            ':hover, :active': {
              background: 'rgba(141, 87, 241, 0.6) !important',
            },
          },
        },
        ...stylesOverrides,
      }}
      disabled={disabled}
      {...rest}
    />
  ) : (
    <FluentDefaultButton
      style={{ minWidth: '180px', ...style }}
      styles={{
        flexContainer: {
          flexDirection: 'row-reverse',
        },
        textContainer: {
          flexGrow: 0,
        },
        root: {
          padding: '20px 10px',
          borderColor: '#8D57F1',
          background: `rgba(141, 87, 241, ${forceActiveStyle ? '1' : '0'})`,
          borderRadius: '20px',
          selectors: {
            'i, span': {
              color: forceActiveStyle ? '#FFFFFF' : '#000000',
            },
            ':hover, :active': {
              background: 'rgba(141, 87, 241, 1.0) !important',
            },
            ':hover i, :hover span, :active i, :active span': {
              color: '#FFFFFF',
            },
          },
        },
        ...stylesOverrides,
      }}
      disabled={disabled}
      {...rest}
    />
  );
};

export const DefaultButtonWithArrow = (props: IDefaultButtonProps) => (
  <DefaultButton
    {...props}
    iconProps={{ iconName: 'SkypeArrow' }}
    stylesOverrides={{ icon: { transform: 'rotate(180deg)' } }}
  />
);
