import React from 'react';

import { Text, ITextProps } from '@fluentui/react';

export const SubHeaderText = (props: ITextProps) => {
  return (
    <Text
      block
      variant={props.variant ?? 'xLargePlus'}
      style={{ lineHeight: '30pt', color: '#13002B' }}
    >
      {props.children}
    </Text>
  );
};
