import { configureStore, combineReducers } from '@reduxjs/toolkit';

import logger from 'redux-logger';
import userInfo, { fetchUserInfo } from './userInfo';
import tasks, { fetchTasks } from './tasks';
import installedReleases, { fetchInstalledReleases } from './installedReleases';
import availableReleases, { fetchAvailableReleases } from './availableReleases';

import {
  useSelector as useReduxSelector,
  useDispatch as useReduxDispatch,
  TypedUseSelectorHook,
} from 'react-redux';

/**
 * @description Root redux state
 */

const reducer = combineReducers({
  userInfo: userInfo.reducer,
  tasks: tasks.reducer,
  installedReleases: installedReleases.reducer,
  availableReleases: availableReleases.reducer,
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export const actionCreators = {
  fetchUserInfo,
  fetchTasks,
  fetchInstalledReleases,
  fetchAvailableReleases,
};

export default store;

export type RootState = ReturnType<typeof store.getState>;

// export typed selector and dispatch
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
export const useDispatch = () => useReduxDispatch<typeof store.dispatch>();
