import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getTasks } from '../utils/api';
import { isFinalStatus } from '../utils/task';
import { Tasks } from '../types/ApiResponses';

export const fetchTasks = createAsyncThunk(
  'tasks/fetch',
  async ({ orgId, take = 10 }: { orgId: number; take?: number }) => {
    const userInfoRes = await getTasks({
      orgId,
      take,
    });

    return userInfoRes.data;
  }
);

interface TasksState {
  entities?: Tasks;
  allowNewTasks: boolean;
  loading: boolean;
  error?: string;
}
const tasksSlice = createSlice({
  name: 'tasks',
  initialState: { loading: false } as TasksState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTasks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchTasks.fulfilled, (state, action) => {
      state.loading = false;
      state.entities = action.payload;

      const lastTask = action.payload?.[0];

      if (!lastTask || isFinalStatus(lastTask?.taskStatuses?.[0]?.status)) {
        state.allowNewTasks = true;
      } else {
        state.allowNewTasks = false;
      }
    });
    builder.addCase(fetchTasks.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.error?.message ?? 'An error occurred';
    });
  },
});

export default tasksSlice;
