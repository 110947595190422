import jwtDecode from 'jwt-decode';
import cookie from 'cookie';

import { ApiQueryParams } from '@mavens/is-types';
import { UserInfo } from '../types/ApiResponses';

/**
 * @description Check if jwt cookie exists and is not expired. Always true in development NODE_ENV
 *              Uses auth0's jwtDecode which cannot handle validation.
 *              Express's jsonwebtoken can handle validation, but adds 100KB to the bundle.
 *
 * @returns boolean
 */
export const isAuthenticated = () => {
  if (process.env.NODE_ENV === 'development') {
    return true;
  } else {
    try {
      // jwt expiration is seconds since epoch.
      const token = cookie.parse(window.document.cookie ?? '')?.jwt ?? '';

      if (!token) {
        return false;
      } else {
        const decoded = jwtDecode<{ userId: number; iat: number; exp: number }>(
          token
        );

        const secondsSinceEpoch = Math.round(new Date().getTime() / 1000);

        return decoded.exp > secondsSinceEpoch;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }
};

/**
 * Get oauth url for MIS Connected App
 *
 * @param oauthState oauth state api param
 */
export const getOauthUrl = (oauthState: ApiQueryParams.OauthState) => {
  return new URL(
    `services/oauth2/authorize?client_id=${
      process.env.REACT_APP_SF_CONNECTED_APP_CLIENT_ID
    }&redirect_uri=https://${
      process.env.REACT_APP_SF_CONNECTED_APP_CB_DOMAIN
    }/api/oauth2/callback&response_type=code${
      oauthState.type === 'signin' ? '&prompt=login' : ''
    }&state=${JSON.stringify(oauthState)}`,
    `https://${oauthState.domain}`
  ).href;
};

/**
 * Get the domain for authenticating with Salesforce
 *
 * @param userInfo {UserInfo}
 *
 */
export const getAuthDomain = (userInfo: UserInfo) => {
  return (
    localStorage.getItem('myDomain') ||
    (userInfo.org.type === 'PROD'
      ? 'login.salesforce.com'
      : 'test.salesforce.com')
  );
};

/**
 * Store MyDomain in local storage
 *
 * @param domain {string}
 *
 */
export const setMyDomain = (domain: string) => {
  localStorage.setItem('myDomain', domain);
};

/**
 * Remove myDomain from local storage
 *
 */
export const removeMyDomain = () => {
  localStorage.removeItem('myDomain');
};
